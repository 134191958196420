import React, { useState } from 'react';

import HeaderBar from './components/HeaderBar/';
import Blog from './pages/Blog.js';
import ContactUs from './pages/ContactUs.js';
import GetStarted from './pages/GetStarted.js';
import Home from './pages/Home.js';
import PageBody from './components/PageBody/';
import Services from './pages/Services.js';

import styles from './App.module.scss';

const App = () => {
  const [view, setView] = useState('home');

  const viewComponent = {
    home: <Home />,
    services: <Services />,
    blog: <Blog />,
    getStarted: <GetStarted />,
    contactUs: <ContactUs />,
  };

  return (
    <div className={styles.style}>
      <HeaderBar setView={setView} />
      <PageBody>
        { viewComponent[view] }
      </PageBody>
    </div>
  );
}

export default App;
