import React from 'react';

import styles from './HeaderBar.module.scss';

const HeaderBar = ({ setView }) => {
  console.log('what is styles', styles);
  return (
    <header className={styles.style}>
      <div>
        Helianthus Dev
      </div>
      <div className={styles.actionButtons}>
        <button className={styles.link} onClick={() => { setView('services') }}>Services</button>
        <button className={styles.link} onClick={() => { setView('blog') }}>Blog</button>
        <button className={styles.link} onClick={() => { setView('getStarted') }}>Get Started</button>
        <button className={styles.cta} onClick={() => { setView('contactUs') }}>Contact Us</button>
      </div>
    </header>
  );
};

export default HeaderBar;
