import React from 'react';

const GetStarted = () => {
  return (
    <div>
      GetStarted
    </div>
  )
};

export default GetStarted;
