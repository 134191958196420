import React from 'react';

import styles from './pages.module.scss';

const Home = () => {
  return (
    <div className={styles.page}>
      <div className={styles.homeImage}>
        <div className={styles.homeImageImage}>
          <div className={styles.heroText}>
            <h1>&lt; Helianthus Development Studios &gt;</h1>
            <h2><span className={styles.emphasis}>Transforming your ideas</span> into your <span className={styles.longEmphasis}>solutions</span>.</h2>
            <div className={styles.heroP}>
              <p>Passionate about product, we breathe life into software projects.</p>
              <p>Let's get started: product ideation & refinement • road mapping • development • delivery</p>
            </div>
            <div className={styles.heroCta}>
              <button>Learn More</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.stages}>
        <div className={styles.stagesInner}>
          <div className={styles.step1}>
            <div className={styles.fog}>
              <p>Product Ideation & Refinement</p>
            </div>
          </div>
          <div className={styles.step2}>
            <div className={styles.fog}>
              <p>Road Mapping</p>
            </div>
          </div>
          <div className={styles.step3}>
            <div className={styles.fog}>
              <p>Development</p>
            </div>
          </div>
          <div className={styles.step4}>
            <div className={styles.fog}>
              <p>Delivery</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Home;
