import React from 'react';

const ContactUs = () => {
  return (
    <div>
      ContactUs
    </div>
  )
};

export default ContactUs;
