import React from 'react';

import styles from './PageBody.module.scss';

const PageBody = ({ children }) => {
  return (
    <div className={styles.style}>
      {children}
    </div>
  );
}

export default PageBody;
