import React from 'react';

const Blog = () => {
  return (
    <div>
      Blog
    </div>
  )
};

export default Blog;
